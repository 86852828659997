export default () => ({
  rootMargin: '0% 0% 0% 0%',
  threshold: 0,
  initialDelay: 0,

  walks: {
    default: {
      interval: 0.2,
      duration: 0.35,
      transition: null
    },
    r: {
      interval: 0.2,
      duration: 0.35,
      transition: null
    },
    l: {
      interval: 0.08,
      duration: 0.35,
      transition: null
    },
    u: {
      interval: 0.1,
      duration: 0.35,
      transition: null
    }
  }
})
