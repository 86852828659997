import { Dom, Events, gsap } from '@brandocms/jupiter'

export default class SmartVideo {
  constructor(app, el) {
    this.app = app
    this.el = el
    this.video = Dom.find(this.el, '[data-video]')
    this.cover = Dom.find(this.el, '[data-cover]')
    this.playButton = Dom.find(this.el, '.video-play-button-wrapper')

    this.autoplay = this.video.hasAttribute('autoplay')
    this.controls = this.video.hasAttribute('controls')

    this.h2 = Dom.find(this.el, '[data-cover] h2')
    this.canPlay = false
    this.canReplay = false
    this.muted = false
    this.txt = null
    this.spinner = null
    this.opts = {
      showText: false,
      showMuteText: false,
      showSpinner: false,
      muteOnClick: false,
      pauseOnClick: true
    }
    this.initialize()
  }

  initialize() {
    // observers
    this.setupRevealObserver()
    this.setupPlayObserver()
    window.addEventListener(Events.APPLICATION_REVEALED, () => {
      this.setupLoadObserver()
    })

    if (this.playButton) {
      // if we have play button, but should show controls
      // -- hide controls until played
      if (this.controls) {
        this.video.controls = false
      }

      this.playButton.addEventListener('click', e => {
        this.hideCover()
        this.showVideo()
        gsap.to(this.playButton, {
          autoAlpha: 0,
          onComplete: () => {
            if (this.controls) {
              this.video.controls = true
            }
          }
        })
      })
    }

    gsap.set(this.video, { autoAlpha: 0 })

    if (this.opts.showText && !this.txt) {
      const el = document.createElement('div')
      gsap.set(el, {
        position: 'absolute',
        fontFamily: 'OBOS',
        color: '#ffffff',
        zIndex: 55,
        top: '50%',
        left: '50%',
        opacity: 0,
        xPercent: -50,
        yPercent: -50,
        id: 'follower',
        fontSize: '60px',
        userSelect: 'none',
        cursor: 'pointer'
      })
      el.setAttribute('data-smart-video-txt', '')
      this.el.append(el)
      this.txt = el
    }

    if (this.opts.showMuteText && !this.muteTxt) {
      const el = document.createElement('div')
      gsap.set(el, {
        position: 'absolute',
        fontFamily: 'OBOS',
        color: '#ffffff',
        zIndex: 55,
        opacity: 0,
        fontSize: '40px',
        userSelect: 'none',
        cursor: 'pointer'
      })
      el.setAttribute('data-smart-video-mute-txt', '')
      this.el.append(el)
      this.muteTxt = el
      this.muteTxt.innerHTML = 'Klikk for lyd'
    }

    if (this.opts.showSpinner && !this.spinner) {
      const el = document.createElement('div')
      gsap.set(el, {
        position: 'absolute',
        color: '#ffffff',
        zIndex: 55,
        top: '50%',
        left: '50%',
        opacity: 0,
        xPercent: -50,
        yPercent: -50,
        scale: 1.5,
        userSelect: 'none',
        pointerEvents: 'none'
      })
      this.el.append(el)
      this.spinner = el
      this.spinner.innerHTML = `

        <!-- By Sam Herbert (@sherb), for everyone. More @ http://goo.gl/7AJzbL -->
        <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg" stroke="#fff">
            <g fill="none" fill-rule="evenodd">
                <g transform="translate(1 1)" stroke-width="2">
                    <circle stroke-opacity=".5" cx="18" cy="18" r="18"/>
                    <path d="M36 18c0-9.94-8.06-18-18-18">
                        <animateTransform
                            attributeName="transform"
                            type="rotate"
                            from="0 18 18"
                            to="360 18 18"
                            dur="1s"
                            repeatCount="indefinite"/>
                    </path>
                </g>
            </g>
        </svg>
      `
    }
  }

  setupLoadObserver() {
    // eslint-disable-next-line compat/compat
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const booted = entry.target.hasAttribute('data-booted')

            if (booted === false) {
              this.boot()
            } else {
              if (this.autoplay) {
                this.play()
              }
            }
          } else {
            const playing = entry.target.hasAttribute('data-playing')
            if (playing === true) {
              if (this.autoplay) {
                this.pause()
                this.getReadyForReplay()
              }
            }
          }
        })
      },
      {
        rootMargin: '10%',
        threshold: 0.0
      }
    )

    observer.observe(this.el)
  }

  hideCover() {
    if (this.h2) {
      gsap.to(this.h2, { delay: 0.4, opacity: 0, ease: 'sine.in' })
      gsap.to(this.h2, { delay: 0.4, x: -125, ease: 'sine.in' })
    }
    if (this.cover) {
      gsap.to(this.video, { autoAlpha: 1 })
      if (this.autoplay) {
        this.play()
      }
      gsap.to(this.cover, {
        delay: 0,
        duration: 0.2,
        opacity: 0,
        onComplete: () => {
          // gsap.set(this.cover, { display: 'none' })
          if (!this.autoplay) {
            this.play()
          }
          this.showMuteTxt()
        }
      })
    } else {
      this.play()
      setTimeout(() => this.play(), 500)
      this.showMuteTxt()
    }
  }

  showMuteTxt() {
    if (this.muted && this.muteTxt) {
      gsap.to(this.muteTxt, { opacity: 1 })
    }
  }

  hideMuteTxt() {
    if (!this.muted) {
      if (this.muteTxt) {
        gsap.to(this.muteTxt, { opacity: 0 })
      }
    }
  }

  showCover() {
    if (this.cover) {
      gsap.to(this.cover, {
        delay: 0,
        opacity: 1
      })
      gsap.set(this.video, { opacity: 0 })
    }

    if (this.h2) {
      gsap.to(this.h2, { delay: 0.9, opacity: 1, ease: 'sine.in' })
      gsap.to(this.h2, { delay: 0.9, x: 0, ease: 'sine.out' })
    }
  }

  reveal() {
    gsap.to(this.el, { autoAlpha: 1 })
    if (this.cover) {
      gsap.to(this.cover, { autoAlpha: 1 })
      this.cover.setAttribute('data-revealed', '')
    } else {
      this.showVideo()
    }
    this.el.setAttribute('data-revealed', '')
  }

  showVideo() {
    gsap.to(this.video, { delay: 0.35, autoAlpha: 1 })
    this.el.setAttribute('data-revealed', '')
    this.video.setAttribute('data-revealed', '')
  }

  setupRevealObserver() {
    // eslint-disable-next-line compat/compat
    this.revealObserver = new IntersectionObserver(
      (entries, self) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.reveal(entry.target)
            self.unobserve(entry.target)
          }
        })
      },
      {
        rootMargin: '10%',
        threshold: 0.0
      }
    )

    this.revealObserver.observe(this.el)

    this.el.addEventListener('click', () => {
      if (this.canReplay) {
        this.replay()
        this.canReplay = false
        this.hideTxt()
      } else {
        if (this.opts.muteOnClick) {
          this.toggleSound()
        } else if (this.opts.pauseOnClick) {
          const playing = this.el.hasAttribute('data-playing')

          if (!this.controls) {
            if (playing) {
              this.pause()
            } else {
              this.play()
            }
          }
        }
      }
    })
  }

  showSpinner() {
    if (this.spinner) {
      gsap.to(this.spinner, {
        delay: 0.3,
        opacity: 1
      })
    }
  }

  hideSpinner() {
    if (this.spinner) {
      gsap.to(this.spinner, { delay: 0.35, opacity: 0 })
    }
  }

  watchForCanPlay() {
    if (this.canPlay && !this.playButton) {
      this.hideSpinner()
      this.hideCover()
      return
    }

    if (this.canPlay && this.playButton) {
      return
    } else {
      setTimeout(() => {
        if (this.video.readyState >= this.video.HAVE_FUTURE_DATA) {
          this.canPlay = true
        }
        this.watchForCanPlay()
      }, 500)
    }
  }

  setupPlayObserver() {
    // eslint-disable-next-line compat/compat
    this.playObserver = new IntersectionObserver(
      (entries, self) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.showSpinner()
            this.watchForCanPlay()
            self.unobserve(entry.target)
          }
        })
      },
      {
        rootMargin: '10%',
        threshold: 0.0
      }
    )

    this.playObserver.observe(this.el)
  }

  hideTxt() {
    if (this.txt) {
      gsap.to(this.txt, { opacity: 0 })
    }
  }

  showTxt() {
    if (this.txt) {
      gsap.to(this.txt, { delay: 1.8, opacity: 1 })
    }
  }

  boot() {
    const dataSrc = this.video.getAttribute('data-src')

    if (dataSrc) {
      this.video.addEventListener('canplay', () => {
        this.canPlay = true
      })

      this.video.addEventListener('loadedmetadata', () => {
        setTimeout(() => {
          this.canPlay = true
        }, 200)
      })

      this.video.src = dataSrc
    }
    // else {
    //   this.canPlay = true
    // }

    this.el.setAttribute('data-booted', '')
  }

  replay() {
    this.video.currentTime = 0
    this.video.load()
    this.hideCover()
  }

  getReadyForReplay() {
    if (this.opts.showText) {
      this.txt.innerHTML = 'Spill av igjen'
    }
    this.canReplay = true
    this.showTxt()
  }

  play() {
    this.video.onended = () => {
      if (!this.autoplay) {
        this.showCover()
      }
      this.getReadyForReplay()
    }

    // this.video.play()
    setTimeout(() => {
      const playPromise = this.video.play()
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            this.el.setAttribute('data-playing', '')
          })
          .catch(() => {
            this.video.play()
          })
      }
    }, 350)
  }

  toggleSound() {
    const sound = this.video.toggleAttribute('data-video-sound')
    this.video.muted = !sound
    this.muted = !sound

    if (sound) {
      this.hideMuteTxt()
      this.video.removeAttribute('muted')
    } else {
      this.showMuteTxt()
      this.video.setAttribute('muted', '')
    }
  }

  pause() {
    this.video.pause()
    this.el.removeAttribute('data-playing')
  }
}
